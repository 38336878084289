/* html {
  font-size: 62.5%;
} */

body {
  margin: 0;
  /* font: 300 11px/1.4 'Poppins' 'sans-serif'; */
  color: #444;
  background: #133051;
  overflow: hidden;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

