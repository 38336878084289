$primary-color: #f39f9f;

@import 'animate.css';


@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/helvetica-neu.ttf') format('ttf');
}

@font-face {
  font-family: 'La Belle Aurore';
  src: url('./assets/fonts/LaBelleAurore.woff2') format('woff2');
}

@font-face {
  font-family: 'Coolvetica';
  src: url('./assets/fonts/CoolveticaRg-Regular.woff2') format('woff2');
}
input,
textarea{
  font-family: 'Poppins';
}

.App {
  height: 100vh;
}


// .loader-active{
//   display:block;
//   position: absolute;
//   left: 0;
//   right: 0;
//   margin: auto;
//   top: 0;
//   bottom: 0;
//   width: 50px;
//   height: 50px;
//   animation: fadeOut 0.5s 0.5s;
//   animation-fill-mode: forwards;
  
// }

// background #133051
// primary #f39f9f
// second #a2deeb
