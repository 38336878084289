.about {
    font-family: "Poppins";
    color: white;
    position: relative;
    min-height: 100vh;
    background-image: url('../../assets/images/galaxy2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    animation: moveBackground 20s ease-in-out infinite alternate;
    z-index: 0;
    
}



// inside-out
.background1 {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #133051;
    clip-path: polygon(0 -1%, 100% 20%, 110% 62%, 0 102%);
    z-index: -100;
    transition: ease-in-out 0.5s;
    animation: fadeInImage 1s ease-in-out backwards;
}

.about-img img {
    width: 35vw;
    animation: fadeInImage 1s ease-in-out forwards;
    filter: drop-shadow(0 0 20px rgba(148, 205, 252, 0.432));
}

.about {
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-content {
    margin-left: 3%;

}

.about-content h3 {
    font-size: 3.2rem;
    font-weight: 700;
    animation: slideInFromRight 0.8s ease-out forwards;

}

.about-content h3:nth-of-type(2) {
    margin-bottom: 3rem;
    animation: slideInFromRight 1.2s ease-out forwards;

}

.about-content h2 {
    font-size: 5.6rem;
    font-weight: 700;
    line-height: 1.3;
    margin-right: 135px;

    animation: slideInFromLeft 0.8s ease-out forwards;
}

.about-content p {
    font-size: 1.6rem;
    animation: slideInFromLeft 0.8s ease-out forwards;
    margin-bottom: 4rem;
    max-width: 810px;
}

span {
    color: #f39f9f
}

@media (max-width: 900px) {
    .about {
        height: 100%;
      padding-top: 15px;
      flex-direction: column;
      align-items: center;
      text-align: center;
      animation: moveBackground 120s ease-in-out infinite alternate;
    }
  
    .about-content h2{
      font-size: 2rem;
      margin-right: 0;
      margin-left: 0;
    }
  
    .about-content h3{
      font-size: 2rem;
      margin-right: 0;
      margin-left: 0;
    }
   
  
    .about-content p {
      font-size: 14px;
      margin: 20px;
      text-align: block;
    }
  
    
    .about-img img {
      padding-top: 15%;
      min-width: 200px;
      
    }
   
    
  }


@keyframes slideInFromLeft {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideInFromRight {
    from {
        transform: translateX(100%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}