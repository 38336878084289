
.home {
  font-family: "Poppins";
  line-height: 0;
  color: white;
  min-height: 100vh;
  position: relative;
  background-color: #133051;
  z-index: 0;

}

.flat-button {
  position: relative;
  color: #f39f9f;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 4px;
  text-decoration: none;
  padding: 10px 18px;
  border: 2px solid;
  animation: fadeIn 1s 0.8s backwards;
  white-space: nowrap;
  z-index: 1000;
  &:hover{
    background-color: #f39f9f;
    color: #133051;
    transition: 0.5s ease-in-out;
    box-shadow:(0 0 30px #a2deeb);
  }
}

.flat-button:hover ~ .background {
  background-color: #133051;
  clip-path: polygon(0 -1%, 100% 20%, 110% 62%, 0 102%);
  z-index: -100;
  transition: ease-in-out 0.5s;
}


.background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url('../../assets/images/galaxy2.jpg');
  background-size: cover;
  clip-path: polygon(-10% 72%, 100% -26%, 150% 62%, 25% 102%);
  z-index: -100;
  animation: moveBackground 20s ease-in-out infinite alternate;
  transition: ease-in-out 0.5s;
}

.p {
  width: 65px;
  animation: pulse0 12s ease-in-out infinite;

}


.home-img img {
  width: 35vw;
  
  animation: fadeInImage 1s ease-in-out forwards, pulse1 6s ease-in-out infinite;
}


.home {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-content {
  margin-left: 3%;


}

.home-content h3 {
  font-size: 3.2rem;
  font-weight: 700;
  animation: slideInFromRight 0.8s ease-out forwards;

}

.home-content h3:nth-of-type(2) {
  margin-bottom: 3rem;
  animation: slideInFromRight 1.2s ease-out forwards;

}

.home-content h1 {
  font-size: 5.6rem;
  font-weight: 700;
  line-height: 1.3;
  margin-right: 135px;

  animation: slideInFromLeft 0.8s ease-out forwards;
}

.home-content p {
  font-size: 1.6rem;
  animation: slideInFromLeft 0.8s ease-out forwards;
  margin-bottom: 4rem;
}

.software {
  color: #f39f9f
}

.surname {
  color: #a2deeb
}

.title-container {

  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  animation: slideInFromRight 1.2s ease-out forwards;
}

.title-container h3 {
  margin-top: 0;
  margin-right: 10px;
}


@media (max-width: 900px) {
  .home {
    padding-top: 15px;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  .home-content h1{
    font-size: 2rem;
    margin-right: 0;
    margin-left: 0;
  }

  .home-content h3{
    font-size: 2rem;
    margin-right: 0;
    margin-left: 0;
  }
 
  .title-container {
    margin-top: 50px;
    flex-direction: column;
  }

  .title-container h3 {
    margin-right: 0;
  }

  .home-content p {
    font-size: 14px;
  }

  
  .home-img img {
    padding-top: 30%;
    min-width: 200px;
    
  }
  .background {
    animation: moveBackground 160s ease-in-out infinite alternate;
  }

  
}



@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}


@keyframes fadeInImage {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes pulse1 {
  0% {
    filter: drop-shadow(0 0 40px black);
    transform: translateY(0);
  }

  50% {
    filter: drop-shadow(0 0 40px white);
    transform: translateY(-1.4rem);
  }

  100% {
    filter: drop-shadow(0 0 40px black);
    transform: translateY(0);
  }
}
@keyframes moveBackground {
  from {
    background-position: 0 0;
    
  }

  to {
    background-position: 100% 100%;

  }
}

@keyframes pulse0 {
  0% {
    filter: drop-shadow(0 0 20px rgba(255, 255, 255, 0.15));

  }

  50% {
    filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.3));

  }

  100% {
    filter: drop-shadow(0 0 20px rgba(255, 255, 255, 0.15));

  }
}
